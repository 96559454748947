import React, { useState } from 'react'
import styled from 'styled-components'
import { Email } from 'components/forms/elements/v2/email'
import { FieldError } from 'components/forms/elements/v2/field-error'
import { Submit } from 'components/forms/elements/v2/submit'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import { useForm } from 'react-hook-form'
import { useIsMounted } from 'hooks/use-is-mounted'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { Link } from 'gatsby'

const SUBMIT_STATE = {
  success: 'success',
  loading: 'loading',
  error: 'error',
}

const BargainBinFormStacked = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid },
  } = useForm({ mode: 'onBlur' })

  const [submitState, setSubmitState] = useState(null)

  const isMounted = useIsMounted()

  const onSubmit = data => {
    setSubmitState(SUBMIT_STATE.loading)

    const body = JSON.stringify(data)

    fetch('/api/bargain-bin-alerts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body,
    })
      .then(res => {
        if (isMounted()) {
          setSubmitState(res.ok ? SUBMIT_STATE.success : SUBMIT_STATE.error)
        }
      })
      .catch(err => {
        if (isMounted()) {
          console.error('Error submitting form: ', err)
          setSubmitState(SUBMIT_STATE.error)
        }
      })
  }

  const onError = errors => {
    console.error(errors)

    if (isValid) {
      setSubmitState(SUBMIT_STATE.error)
    }
  }
  return (
    <React.Fragment>
      {submitState !== SUBMIT_STATE.success && (
        <form
          name='Bargain Bin Alerts'
          onSubmit={handleSubmit(onSubmit, onError)}
          method='post'
          id='bargain-bin-alerts-form'
          data-netlify='true'
        >
          <Container>
            <div>
              <FormElements>
                <StyledEmail register={register} placeholder='Enter your email' required />
                {errors['Email'] ? <StyledFieldError type={errors['Email'].type} /> : null}
                <div style={{ left: '-9999px', top: '-9999px' }} className='visually-hidden'>
                  <label htmlFor='pardot_extra_field' tabIndex='-1'>
                    Comments
                  </label>
                  <input
                    ref={register()}
                    type='text'
                    id='pardot_extra_field'
                    name='pardot_extra_field'
                    tabIndex='-1'
                  />{' '}
                </div>
                <Spacer size='m' />
                <Submit loading={isSubmitting || submitState === SUBMIT_STATE.loading} />
              </FormElements>
            </div>
          </Container>
        </form>
      )}
      {submitState === SUBMIT_STATE.success && (
        <React.Fragment>
          <H2 as={SuccessHeader}>Thanks for subscribing!</H2>
          <SuccessMesssage>
            You're on the list! We'll let you know when new equipment is added.
          </SuccessMesssage>
          <Spacer size='s' />
          <div>
            <Button ghost color='yellow' as={ButtonLink} to='/used-equipment/bargain-bin/'>
              View Bargain Bin equipment
            </Button>
          </div>
        </React.Fragment>
      )}
      {submitState === SUBMIT_STATE.error && (
        <ErrorMessage>
          There was an issue submitting your form. Please try again. If problems persist, please
          contact <a href='mailto:contactus@hutsoninc.com'>our support team</a>.
        </ErrorMessage>
      )}
    </React.Fragment>
  )
}

const Container = styled.div`
  @media (min-width: 800px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const FormElements = styled.div`
  label {
    display: none;
  }

  .submit-loading-circle {
    border-color: rgba(255, 255, 255, 0.15);
    border-top-color: rgba(255, 255, 255, 0.75);
  }
`

const StyledEmail = styled(Email)`
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  max-width: 250px;

  @media (min-width: 480px) {
    max-width: none;
    min-width: 250px;
  }
`

const StyledFieldError = styled(FieldError)`
  color: rgba(255, 255, 255, 0.75);
`

const SuccessHeader = styled.span`
  color: #fff;
  display: block;
  margin-bottom: 8px;
`

const SuccessMesssage = styled(P)`
  color: #fff;
  margin-bottom: 0;
`

const ErrorMessage = styled(P)`
  color: #fff;
  margin-top: 16px;

  a {
    color: #fff;
    text-decoration: underline;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export default BargainBinFormStacked
