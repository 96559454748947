import React from 'react'
import styled from 'styled-components'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from 'components/new/spacer'
import HeaderSpacer from 'components/layout/header/spacer'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Form from 'components/forms/bargain-bin-stacked'

const PartsPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Subscribe to the Bargain Bin Newsletter | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Used Equipment',
                'item': 'https://www.hutsoninc.com/used-equipment/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Bargain Bin',
                'item': 'https://www.hutsoninc.com/used-equipment/bargain-bin/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Subscribe',
                'item': 'https://www.hutsoninc.com/used-equipment/bargain-bin/subscribe/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGrid>
        <ImageColumn>
          <GatsbyImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt=''
            style={{ width: '100%', height: '100%', position: 'absolute' }}
            objectFit='cover'
            objectPosition='50% 50%'
          />
        </ImageColumn>
        <TitleColumn>
          <HeaderSpacer />
          <TitleContainer>
            <Title>Bargain Bin Newsletter</Title>
            <P className='copy'>
              The <Link to='/used-equipment/bargain-bin/'>Bargain Bin</Link> is our hand-picked list
              of used equipment at rock-bottom prices! Subscribe to our email newsletter to get
              updates with newly-added units and offers.
            </P>
            <Spacer size='s' />
            <Form />
          </TitleContainer>
        </TitleColumn>
      </HeroGrid>
    </Layout>
  )
}

const HeroGrid = styled.div`
  background-color: ${props => props.theme.color.g400};

  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row;
  }
`

const ImageColumn = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  width: auto;

  @media (min-width: 900px) {
    height: auto;
    overflow: auto;
    padding-bottom: 0;
    ${column('1/2', 0)}
  }
`

const TitleColumn = styled.div`
  @media (min-width: 900px) {
    ${column('1/2', 0)}
    display: flex;
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
  padding: 36px 24px;

  .copy {
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;

      :hover,
      :visited {
        text-decoration: none;
      }
    }
  }

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 64px;
  }
`

const Title = styled.h1`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  padding: 0;
  margin: 16px 0 0;

  @media (min-width: 900px) {
    font-size: 48px;
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "bargain-bin-newsletter-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PartsPage
